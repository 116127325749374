import React, { useContext, useState, useEffect, useCallback } from "react"
import { Loader, DrawerHeader } from "."
import {
  AppraiseStep0,
  AppraiseStep1,
  AppraiseStep2,
  AppraiseStep3,
  AppraiseStep4,
  AppraiseStep5,
  AppraiseStep6,
  AppraiseStep7,
  AppraiseThanks1,
  AppraiseThanks2,
  StepProps,
} from "./appraise-steps"
import { CarContext, KeyboardContext, UIContext } from "../context"
import { getBrands } from "../api"
import { UpdateStatType, useUpdateStats } from "../hooks"
import { FormData } from "./appraise-steps"

export const DrawerAppraise: React.FC = () => {
  const { SetCarPaymentInfo, financeData } = useContext(CarContext)
  const { isKeyboardOpen } = useContext(KeyboardContext)
  const { closeAppraiseDrawer, openDemoAlert } = useContext(UIContext)
  const [updateStats] = useUpdateStats("", UpdateStatType.CALLBACK)

  const [form, updateForm] = useState<FormData>({
    reasonForThanksPage: null,
    plate: "",
    brand: undefined,
    model: undefined,
    year: undefined,
    version: undefined,
    kms: undefined,
    stars: 4,
    carKeys: false,
    oneOwner: false,
    maintenanceStatus: null, // null to display "N/A" on email
    changeTires: null, // null to display "N/A" on email
    changeTiresQuantity: null, // null to display "N/A" on email
    name: "",
    email: "",
    phone: "",
    info: {
      stepHistory: [],
      step: 0,
      loading: false,
      plateFallback: false,
    },
    actions: {},
    errors: {
      plate: true,
      name: true,
      phone: true,
      email: true,
    },
    bonusPercentage: 3,
    maxKmsAppraised: 100000,
  })

  useEffect(() => {
    if (financeData) {
      updateForm(prev => ({
        ...prev,
        bonusPercentage: financeData.otherParams.bonusPercentage,
        maxKmsAppraised: financeData.otherParams.maxKmsAppraised || 100000,
      }))
    }
  }, [financeData])

  const handleChange = useCallback(
    ({ name, value, hasError }) => {
      updateForm(prev => ({
        ...prev,
        [name]: value,
      }))
      updateForm(prev => ({
        ...prev,
        errors: {
          ...prev.errors,
          [name]: hasError,
        },
      }))
    },
    [updateForm]
  )

  useEffect(() => {
    getBrands().then(brandList => {
      updateForm(prev => ({
        ...prev,
        info: {
          ...prev.info,
          brands: brandList
            .map(e => ({
              value: e.id,
              label: e.name,
            }))
            .sort((a, b) =>
              a.label > b.label ? 1 : a.label < b.label ? -1 : 0
            ),
        },
      }))
    })
    updateForm(prev => ({
      ...prev,
      actions: {
        nextStep: async (skipPlateFallback = true) =>
          updateForm(prev => ({
            ...prev,
            info: {
              ...prev.info,
              stepHistory: [...prev.info.stepHistory, prev.info.step],
              step:
                prev.info.step === 0
                  ? skipPlateFallback
                    ? 2
                    : 1
                  : prev.info.step > 0
                  ? prev.info.step + 1
                  : prev.info.step - 1,
            },
          })),
        prevStep: () =>
          updateForm(prev => ({
            ...prev,
            info: {
              ...prev.info,
              stepHistory: prev.info.stepHistory.slice(0, -1),
              step:
                prev.info.step === 2
                  ? prev.info.plateFallback
                    ? 1
                    : 0
                  : prev.info.step > 0
                  ? prev.info.step - 1
                  : prev.info.step + 1,
            },
          })),
        thanksPage: reason =>
          updateForm(prev => ({
            ...prev,
            reasonForThanksPage: reason,
            info: {
              ...prev.info,
              stepHistory: [...prev.info.stepHistory, prev.info.step],
              step: -1,
            },
          })),
        notMyCar: () => {
          updateForm(prev => ({
            ...prev,
            info: {
              ...prev.info,
              carInfo: { plate: prev.plate },
              versions: null,
              stepHistory: [...prev.info.stepHistory, prev.info.step],
              step: 1,
              plateFallback: true,
            },
          }))
        },
        closeAppraiseDrawer: accepted => {
          accepted && updateStats("appraise-general-step-8")
          closeAppraiseDrawer()
        },
        SetCarPaymentInfo,
        openDemoAlert,
      },
    }))
  }, [closeAppraiseDrawer, SetCarPaymentInfo, updateForm, updateStats])

  const toggleLoading = useCallback(
    () =>
      updateForm(prev => ({
        ...prev,
        info: { ...prev.info, loading: !prev.info.loading },
      })),
    [updateForm]
  )

  useEffect(() => {
    const s = form.info.step
    updateStats(
      `appraise-general-${s < 0 ? "thanksStep" : "step"}-${Math.abs(s)}`
    )
  }, [form.info.step, updateStats])

  const [availableAppraiseYears] = useState(
    [...Array(financeData?.otherParams?.availableAppraiseYears).keys()].map(
      val => ({
        value: new Date().getFullYear() - val,
        label: String(new Date().getFullYear() - val),
      })
    )
  )

  return (
    <div
      className={`drawer-layout drawer-appraise ${
        isKeyboardOpen ? "smaller" : ""
      }`}
    >
      <DrawerHeader
        // className="appraise-drawer-header"
        title="TASACIÓN"
        drawerName="appraise-drawer"
        closeDrawer={closeAppraiseDrawer}
      />
      <div className={`content ${isKeyboardOpen ? "keyboard-open" : ""}`}>
        {getChildComponent(form.info.step, {
          form,
          updateForm,
          toggleLoading,
          handleChange,
          availableAppraiseYears,
        })}
      </div>
      {form.info.loading && <Loader />}
    </div>
  )
}

const getChildComponent = (step: number, props: StepProps) => {
  if (step === 0) return AppraiseStep0(props) // Ask for plate
  if (step === 1) return AppraiseStep1(props) // Ask for brand, model and year (plate fallback)
  if (step === 2) return AppraiseStep2(props) // Ask for version and kms
  if (step === 3) return AppraiseStep3(props) // Ask for car state woth 1-5 stars rating
  if (step === 4) return AppraiseStep4(props) // Ask for car maintenances up to date
  if (step === 5) return AppraiseStep5(props) // Ask for car pair of keys and single owner
  if (step === 6) return AppraiseStep6(props) // Ask for car tires replacement
  if (step === 7) return AppraiseStep7(props) // Display final Price
  if (step === -1) return AppraiseThanks1(props) // Ask for personal data to finish appraise unsuccessfully
  if (step === -2) return AppraiseThanks2(props) // Exit message
  return null
}
