import React from "react"
import { CarInfo, StepProps } from "."
import { DCXButton } from ".."
import { parseNumber } from "../../utils"
import { Divider } from "@material-ui/core"

const roundFinalPrice = (finalPrice: number, magnitude: number) => {
  return Math.round(finalPrice / magnitude) * magnitude
}

export const AppraiseStep7: React.FC<StepProps> = ({ form }) => {
  const finishStep = () => {
    form.actions.SetCarPaymentInfo({
      finalPrice: form.info.carInfo.finalPrice,
      plate: form.info.carInfo.plate as string,
      date: new Date().getTime(),
    })
    form.actions.closeAppraiseDrawer(true)
  }
  const notRoundedPrice = form.info.carInfo?.notRoundedFinalPrice ?? 0
  const roundedPriceHigh = roundFinalPrice(
    notRoundedPrice * (1 + form.bonusPercentage / 100),
    100000
  )
  const roundedPriceLow = roundFinalPrice(
    notRoundedPrice * (1 - form.bonusPercentage / 100),
    100000
  )
  return (
    <>
      <CarInfo data={form} />
      <div className="title">Recibimos tu auto en:</div>
      <div className="subtitle">Precio Alto (+{form.bonusPercentage}%)</div>
      <div className="component">
        <div className="amount">${parseNumber(roundedPriceHigh)}</div>
      </div>
      <Divider />
      <div className="subtitle">Precio Medio</div>
      <div className="component">
        <div className="amount">
          ${parseNumber(form.info.carInfo.finalPrice)}
        </div>
      </div>
      <Divider />
      <div className="subtitle">Precio Bajo (-{form.bonusPercentage}%)</div>
      <div className="component">
        <div className="amount">${parseNumber(roundedPriceLow)}</div>
      </div>
      <div className="buttons">
        <DCXButton
          className="button-back"
          id="back-step-7"
          type="secondary"
          text="ATRÁS"
          onClick={form.actions.prevStep}
        />
        <DCXButton
          className="button-next save-appraise"
          id="accept-final-value"
          type="primary"
          text="SIMULAR"
          onClick={finishStep}
        />
      </div>
    </>
  )
}
