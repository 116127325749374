import React from "react"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import { CarInfo, FormData, StepProps } from "."
import { DCXButton } from ".."
import { getFinalPrice, saveAppraise } from "../../api"

const beforeSaveAppraise = (
  prev: FormData,
  finalPrice?: number,
  addCurrStep = false,
  notRoundedFinalPrice?: number
): FormData => ({
  ...prev,
  info: {
    ...prev.info,
    carInfo: {
      ...prev.info.carInfo,
      finalPrice,
      notRoundedFinalPrice,
    },
    stepHistory: addCurrStep
      ? [...prev.info.stepHistory, prev.info.step]
      : prev.info.stepHistory,
  },
})

export const AppraiseStep6: React.FC<StepProps> = ({
  form,
  updateForm,
  toggleLoading,
}) => {
  const finishStep = async () => {
    toggleLoading()
    const basePrice = form.info.carInfo.suggestedPrice
    const apiPrice = await getFinalPrice(
      form.info.carInfo.brand_name,
      form.info.carInfo.model_name,
      basePrice,
      form.stars,
      form.carKeys,
      form.changeTires,
      form.changeTiresQuantity,
      form.maintenanceStatus
    )

    updateForm(prev => ({
      ...beforeSaveAppraise(
        prev,
        apiPrice?.finalPrice,
        false,
        apiPrice?.notRoundedFinalPrice
      ),
      changeTires: prev.changeTires === null ? false : prev.changeTires,
    }))
    saveAppraise(
      {
        ...beforeSaveAppraise(
          form,
          apiPrice?.finalPrice,
          true,
          apiPrice?.notRoundedFinalPrice
        ),
        changeTires: form.changeTires === null ? false : form.changeTires,
      },
      true
    )
    form.actions.nextStep()
    toggleLoading()
  }

  return (
    <>
      <CarInfo data={form} />
      <div className="title">¿Tendremos que cambiar algún neumático?</div>
      <div className="subtitle"></div>
      <div className="component">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="Neumáticos"
            name="tires"
            value={Boolean(form.changeTires)}
            onChange={(_, newValue) => {
              updateForm(prev => ({
                ...prev,
                changeTires: newValue === "true",
                changeTiresQuantity:
                  newValue === "true" ? prev.changeTiresQuantity || 1 : null,
              }))
            }}
          >
            <FormControlLabel
              id="select-change-tires-false"
              value={false}
              control={<Radio />}
              label={TEXTS.false}
            />
            <FormControlLabel
              id="select-change-tires-true"
              value={true}
              control={<Radio />}
              label={TEXTS.true}
            />
            <div className="between-options-desc">
              Si alguno de tus neumáticos está muy liso (menos de 2 mm de
              relieve) o está dañado (piquetes o pierde aire) se debe cambiar.
            </div>
          </RadioGroup>
        </FormControl>
        {form.changeTires && (
          <FormControl
            className="select-dense inside-input"
            id="select-change-tires-quantity"
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-native-simple-3">
              ¿Cuántos?
            </InputLabel>
            <Select
              native
              value={form.changeTiresQuantity}
              onChange={event => {
                const {
                  target: { value },
                } = event
                updateForm(prev => ({
                  ...prev,
                  changeTiresQuantity: value ? Number(value) : null,
                }))
              }}
              label="¿Cuántos?"
              inputProps={{
                name: "how-many",
                id: "outlined-native-simple-3",
              }}
            >
              {[1, 2, 3, 4, 5].map(e => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
      <div className="buttons">
        <DCXButton
          className="button-back"
          id="back-step-6"
          type="secondary"
          text="ATRÁS"
          onClick={form.actions.prevStep}
        />
        <DCXButton
          className="button-next send"
          id="finish-step-6"
          type="primary"
          text="TASA TU AUTO"
          onClick={finishStep}
        />
      </div>
    </>
  )
}

const TEXTS = {
  true: "Sí, tendrán que cambiarlo",
  false: "No es necesario",
}
